import React from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import {
  Section,
  SectionText,
  TwoColumn,
  TopHeader,
} from "../../components/Section"
import Seo from "../../components/Seo"

export default function AsbestosRemval() {
  return (
    <Layout>
      <Seo
        title="Demolition"
        description="We provide hassle free demolition services in Vancouver and areas nearby."
      />
      <Section>
        <TopHeader>
          <h1>Demolition services</h1>
        </TopHeader>
        <TwoColumn>
          <SectionText>
            <p>
              A permit is needed in order to provide demolition services in
              Vancouver BC. We have over 8 years experience and the equipment to
              offer you a quality job at a reasonable price. This makes safety
              and punctuality our top priority. Whether you are a homeowner or a
              business proprietor, you can rely on our expertise to demolish
              residential and commercial buildings or any other renovation
              project. We also include Asbestos removal in our services, so if
              you have any old buildings to get rid of, call us.{" "}
            </p>
          </SectionText>
          <SectionText>
            <p>
              Our experience enables us to provide hassle free construction
              demolition without any environmental issues. We are able to
              demolish your facilities when your business is temporarily closed
              for remodelling or even if is fully operating.{" "}
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
      <Section>
        <StaticImage
          src="../../images/demolition-service.jpg"
          alt="A bulldozer at a jobsite in Vancouver"
          placeholder="blurred"
          aspectRatio={4 / 2.1}
          layout="fullWidth"
          style={{
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
          }}
        />
      </Section>
      <Section>
        <TwoColumn>
          <SectionText>
            <p>
              We further take care of removing all waste in the shortest time
              possible to dispose of it in the proper landfill. Conditions on
              reuse and recycle demolition material that is prohibited as waste
              depends on the municipality where the job will take place and the
              type and year of construction of the structure to be demolished.
              If any hazardous material is found before or during the job, our
              trained and skilled team will manage it in a proper manner.
            </p>
          </SectionText>
          <SectionText>
            <p>
              We understand that renovation and demolishing processes can be
              stressful. If you have the desire of making a construction project
              and are indecisive on how to handle it, with A&D Demolition you
              can be sure that your project will be carefully delivered to give
              you the peace of mind you deserve, eliminating any risk along the
              way. Our Demolition services are available 24 hours a day, 7 days
              a week. Do not hesitate to contact us for a free on-site
              evaluation! Our knowledgeable, helpful and friendly team will be
              happy to assist you!
            </p>
          </SectionText>
        </TwoColumn>
      </Section>
    </Layout>
  )
}
